<template>
  <div>
    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
    <v-card flat class="pa-3 mt-2">
      <v-card-text>
        <v-card-title class="text-no-wrap pt-1 ps-2"> UPDATE KATEGORI PRODUK</v-card-title>
        <form @submit.prevent="CreateData" ref="form">
          <v-row>
            <v-col cols="12" v-if="errors.length">
              <v-alert color="error" v-for="error in errors" :key="error" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning"> </v-icon>
                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">{{ error }}</p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field
                label="Nama Kategori Produk"
                v-model="kategoriproduks.nama_kategoriproduk"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-card-text class="text-no-wrap pt-1 ps-1"> Gambar Kategori Produk</v-card-text>
              <!-- <v-img :lazy-src="showPhoto()" max-height="150" max-width="150" :src="showPhoto()"></v-img> -->
              <div class="cropper-area">
                <div class="img-cropper">
                  <vue-cropper
                    ref="cropper"
                    :key="showPhoto"
                    :aspect-ratio="2 / 2"
                    :src="showPhoto()"
                    preview=".preview"
                  />
                </div>
              </div>

              <v-btn type="submit" color="primary" @click.prevent="showFileChooser" class="me-3 mt-4">
                Choose Image
              </v-btn>
              <v-btn type="submit" color="primary" @click.prevent="cropImage" class="me-3 mt-4"> Crop </v-btn>

              <input style="display: none" ref="input" type="file" accept="image/*" @change="setImage" />
              <br />
              <br />
              <v-card-text class="text-no-wrap pt-1 ps-1"> Crop Gambar Kategori Produk</v-card-text>
              <section class="preview-area">
                <!-- <p>Cover</p> -->
                <div class="cropped-image">
                  <img :src="updatePhoto()" />
                  <!-- <img width="200px" :src="clients.photo == '' ? defautlPhoto() : clients.photo" alt="Cropped Image" /> -->
                </div>
              </section>
            </v-col>

            <v-col cols="12">
              <v-btn type="submit" color="primary" class="me-3 mt-4"> Update </v-btn>

              <router-link :to="{ name: 'kategoriproduks-index' }">
                <v-btn color="secondary" outlined class="mt-4" type="reset"> Cancel </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mdiCheckBold, mdiSlashForward } from '@mdi/js'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  components: {
    VueCropper,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/admin/dashboard',
        },
        {
          text: 'Kategori Produk',
          disabled: false,
          href: '/admin/kategori-produks',
        },
        {
          text: 'Update Kategori Produk',
          disabled: true,
        },
      ],

      icons: {
        mdiCheckBold,
        mdiSlashForward,
      },

      kategoriproduks: {
        nama_kategoriproduk: '',
        icon_kategoriproduk: '',
      },
      errors: [],

      page: 1,
      perPage: 10,
      pages: [],
      search: '',
      searchResult: [],
    }
  },
  mounted() {
    this.$isLoading(true)
    setTimeout(() => {
      this.getAllUsers()
      this.$isLoading(false)
    }, 2000)
  },
  methods: {
    getAllUsers() {
      // let uri = `/api/admin/roles/${this.$route.params.id_role}/show`
      let uriUsers =
        process.env.VUE_APP_ROOT_API + `/api/admin/kategoriproduks/${this.$route.params.id_kategoriproduk}/show`
      this.axios.get(uriUsers).then(response => {
        this.kategoriproduks = response.data.kategoriproduk
      })
    },

    updatePhoto() {
      if (this.kategoriproduks.icon_kategoriproduk == null || this.kategoriproduks.icon_kategoriproduk == '') {
        // return "../../../../images/guru/no-image.jpg";
        let imageDefault = process.env.VUE_APP_ROOT_API + `/images/menu/produk.png`
        return imageDefault
      }

      let img = this.kategoriproduks.icon_kategoriproduk

      if (img.length > 100) {
        return this.kategoriproduks.icon_kategoriproduk
      } else {
        let ImageMembers =
          process.env.VUE_APP_ROOT_API +
          `/images/menu/${this.kategoriproduks.id_kategoriproduk}/${this.kategoriproduks.icon_kategoriproduk}`
        return ImageMembers
      }
    },

    showPhoto() {
      if (
        typeof this.kategoriproduks.icon_kategoriproduk === 'string' &&
        this.kategoriproduks.icon_kategoriproduk.length != 0
      ) {
        if (this.kategoriproduks.icon_kategoriproduk.length > 100) {
          return this.kategoriproduks.icon_kategoriproduk
        }
        let ImageMembers =
          process.env.VUE_APP_ROOT_API +
          `/images/menu/${this.kategoriproduks.id_kategoriproduk}/${this.kategoriproduks.icon_kategoriproduk}`
        return ImageMembers
      }
      let imageDefault = process.env.VUE_APP_ROOT_API + `/images/menu/produk.png`
      return imageDefault
    },

    setImage(e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file')
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = event => {
          this.kategoriproduks.icon_kategoriproduk = event.target.result
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.kategoriproduks.icon_kategoriproduk = this.$refs.cropper.getCroppedCanvas().toDataURL()
    },
    showFileChooser() {
      this.$refs.input.click()
    },

    CreateData(e) {
      // console.log('ID_ROLE: ' + this.users.id_role)

      if (this.$data.kategoriproduks.nama_kategoriproduk.length === 0) {
        // this.errors.push('Nama Role harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Nama kategori produk wajib diisi !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else {
        let uri = process.env.VUE_APP_ROOT_API + `/api/admin/kategoriproduks/${this.$route.params.id_kategoriproduk}`
        this.axios
          .post(uri, this.kategoriproduks)
          .then(response => {
            this.$swal.fire({
              title: 'Success',
              text: 'Kategori produk update successfully',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 1000,
            })

            // this.$router.push({ name: 'users-index' })
          })
          .catch(e => {
            this.$swal.fire({
              title: 'Error!',
              html: this.kategoriproduks.nama_kategoriproduk + ' sudah digunakan !',
              icon: 'error',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 2000,
            })
          })

        e.preventDefault()
        setTimeout(() => {
          this.$router.push({ name: 'kategoriproduks-index' })
        }, 3000)
      }
    },
  },
}
</script>
